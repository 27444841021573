module.exports = {
  siteTitle: "The Football Prenup by Buffalo Wild Wings",
  siteTitleAlt: "Buffalo Wild Wings Football Prenup", // This allows an alternative site title for SEO schema.
  publisher: "Buffalo Wild Wings", // Organization name used for SEO schema
  siteDescription:
    "This season, don’t get blindsided by everyday life. Save football. Save your relationship. Save America with a Football Prenup.",
  // MAKE SURE TO CHANGE THIS TO PRODUCTION URL ONCE TESTING IS DONE!
  siteUrl: "https://footballprenup.com", // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: "Buffalo Wild Wings", // Author for RSS author segment and SEO schema
  authorUrl: "https://www.buffalowildwings.com", // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: "@BWWings", // Change for Twitter Cards
  shortTitle: "The Football Prenup", // Used for App manifest e.g. Mobile Home Screen
  shareTitle: "The Football Prenup by Buffalo Wild Wings",
  shareDownloadDescription:
    "I just saved football and my relationship with a #FootballPrenup from Buffalo Wild Wings. (Yeah, you read that right.)",
  shareDescription: "Can we talk about this #FootballPrenup?",
  shareTwitterDescription:
    "Can we talk about this #FootballPrenup from Buffalo Wild Wings? You’ve gotta get one.",
  shareImage: "/logos/buffalo-wild-wings-logo.png", // Open Graph Default Share Image. 1200x630 is recommended
  shareImageWidth: 500, // Change to the width of your default share image
  shareImageHeight: 500, // Change to the height of your default share image
  siteLogo: "/logos/buffalo-wild-wings-logo.png", // Logo used for SEO, RSS, and App manifest
  backgroundColor: "#151c23", // Used for Offline Manifest
  themeColor: "#FFFFFF", // Used for Offline Manifest
  copyright: "Copyright © 2019 Buffalo Wild Wings", // Copyright string for the RSS feed
}
