/* eslint-disable */

import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import IconArrowL from "../images/icon-arrow-left-inline.svg"
import IconRemove from "../images/icon-remove-inline.svg"

import { connect } from "react-redux"

import {
  getGamePairs,
  getTradeOffs,
  getFavoriteTeamName,
  getPartnerName,
  getFanName,
  setFanName as setFanNameAction,
  setFavoriteTeamName as setFavoriteTeamNameAction,
  setPartnerName as setPartnerNameAction,
  addGamePair as addGamePairAction,
  removeGamePair as removeGamePairAction,
  setGamePair as setGamePairAction,
  addTradeOff as addTradeOffAction,
  removeTradeOff as removeTradeOffAction,
  setTradeOff as setTradeOffAction,
} from "../datastore/form-data"

const mapDispatchToProps = {
  setFanName: setFanNameAction,
  setFavoriteTeamName: setFavoriteTeamNameAction,
  setPartnerName: setPartnerNameAction,
  addGamePair: addGamePairAction,
  removeGamePair: removeGamePairAction,
  setGamePair: setGamePairAction,
  addTradeOff: addTradeOffAction,
  removeTradeOff: removeTradeOffAction,
  setTradeOff: setTradeOffAction,
}

const mapStateToProps = state => ({
  gamePairs: getGamePairs(state),
  tradeOffs: getTradeOffs(state),
  favoriteTeamName: getFavoriteTeamName(state),
  partnerName: getPartnerName(state),
  fanName: getFanName(state),
})

// function sum() { var args = Array.from(arguments); return args.reduce((x, s) => x + s, 0) }
// sum(1, 2, 3, 4) // 10

export class MasterForm extends React.Component {
  state = {
    step: 0, // TODO: Change this back to 0 when done.
    stepComponents: [Step1, Step2, Step3, Step4],
    stepTitle: [
      { stepTitle: "Let's Kick Things Off." },
      { stepTitle: "It's Game Time." },
      { stepTitle: "Make Your Trades." },
      { stepTitle: "Let's Review." },
    ],
  }

  goToPreviousPage = () => {
    const step = this.state.step - 1

    if (step >= 0) {
      this.setState({ step })
      return
    }

    console.log("Already on first page")
    navigate("/") // Navigate back to homepage
  }

  goToPage = step => {
    this.setState({ step })
  }

  goToNextPage = (e) => {
    e.preventDefault();
    const step = this.state.step + 1

    if (step < this.state.stepComponents.length) {
      this.setState({ step })
      return
    }

    // Prepare the final object

    console.log("Calling this.props.onFinishSurvey")
    navigate("/download")
  }
  render() {
    const { step } = this.state
    const StepComponent = this.state.stepComponents[step]

    console.log("step: " + step)

    if (!StepComponent) {
      return <div>No more steps!</div>
    }

    return (
      <Layout>
        <SEO title="Personalized Prenup" />
        <div id="personalized-prenup" className={"bg-step-" + (step + 1)}>
          <div onClick={this.goToPreviousPage} id="corner-triangle" data-prevbutton={"prev-step"+ (step + 1)}>
            <div className="corner-triangle-text text-capitalize">
              <div className="corner-triangle-firstline">
                <IconArrowL />
              </div>
            </div>
          </div>
          <Row>
            <Col>
              <div className="steps">
                Step {step + 1} of {this.state.stepComponents.length}
              </div>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col>
                <h1 className="prenup-title">
                  {this.state.stepTitle[step].stepTitle}
                </h1>
              </Col>
            </Row>
            <StepComponent
              goToPage={this.goToPage}
              goToNextPage={this.goToNextPage}
            />
          </Container>
        </div>
      </Layout>
    )
  }
}

const Step1Component = props => {
  const { fanName, partnerName, setPartnerName, setFanName, goToNextPage } = props
  return (
    <form onSubmit={goToNextPage}>
      <Row>
        <Col md={6} sm={10} className="col-centered">
          <p>Here’s an easy one. What’re your names?</p>
          <input
            className="form-control"
            id="nameFan"
            name="nameFan"
            type="text"
            placeholder="Enter Fan's Name"
            value={fanName}
            onChange={event => setFanName(event.target.value)}
            required />
          <label>(First &amp; Last Name)</label>
          <input
            className="form-control"
            id="namePartner"
            name="namePartner"
            type="text"
            placeholder="Enter Partner's Name"
            value={partnerName}
            onChange={event => setPartnerName(event.target.value)}
            required />
          <label>(First &amp; Last Name)</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            type="submit"
            className="btn btn-next btn-blue-grey"
            data-nextbutton="next-step1"
          >
              Next
          </button>
        </Col>
      </Row>
    </form>
  )
}
const Step1 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1Component)

const Step2Component = props => {
  const {
    goToNextPage,
    setGamePair,
    setFavoriteTeamName,
    removeGamePair,
    addGamePair,
    favoriteTeamName,
    gamePairs,
  } = props

  const gamePairComponents = gamePairs.map((gamePair, i) => {
    const [teamName1, teamName2] = gamePair

    return (
      <Row className="game-pair" key={`gamePair-${i}`}>
        <Col xs={10} md={5}>
          <input
            className="form-control"
            id={teamName1}
            name={teamName1}
            type="text"
            placeholder="Enter Team 1 Name"
            value={teamName1}
            onChange={event => setGamePair(i, [event.target.value, teamName2])}
          />
        </Col>
        <Col xs={12} md={1}>vs.</Col>
        <Col xs={10} md={5}>
          <input
            className="form-control"
            id={teamName2}
            name={teamName2}
            type="text"
            placeholder="Enter Team 2 Name"
            value={teamName2}
            onChange={event => setGamePair(i, [teamName1, event.target.value])}
          />
        </Col>
        <Col xs={2} md={1}>
          <div onClick={() => removeGamePair(i)} className="btn-delete">
            {i != 0 ? <IconRemove /> : ''}
          </div>
        </Col>
      </Row>
    )
  })

  return (
    <form onSubmit={goToNextPage}>
      <Row>
        <Col>
          <p>
            This is the part where the Fan protects their favorite team’s
            regular season games.
          </p>
          <input
            className="form-control"
            id="teamFav"
            name="teamFav"
            type="text"
            placeholder="Fan’s Favorite Team"
            value={favoriteTeamName}
            onChange={event => setFavoriteTeamName(event.target.value)}
            required
          />
          <label>(College or Pro Teams)</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="optional">(Optional)</p>
          <p className="additional-games">
            Have other games that are super important? We get that. Add them
            here:
          </p>
        </Col>
      </Row>
      <br />
      {gamePairComponents}
      <Row>
        <Col>
          <a onClick={addGamePair} className="btn btn-next btn-blue-grey">
            + Game
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            type="submit"
            className="btn btn-next btn-blue-grey"
            data-nextbutton="next-step2"
          >
            Next
          </button>
        </Col>
      </Row>
    </form>
  )
}
const Step2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2Component)

const Step3Component = props => {
  const { tradeOffs, setTradeOff, removeTradeOff, addTradeOff, goToNextPage } = props
  const tradeOffComponents = tradeOffs.map((tradeOff, i) => {
    const [tradeOffName] = tradeOff

    return (
      <Row key={`tradeOffName-${i}`}>
        <Col sm={11}>
          <input
            className="form-control"
            id={tradeOffName}
            name={tradeOffName}
            type="text"
            placeholder="Enter Trade-Off"
            value={tradeOffName}
            onChange={event => setTradeOff(i, [event.target.value])}
            required
          />
        </Col>
        <Col sm={1}>
          <div onClick={() => removeTradeOff(i)}>
            {i != 0 ? <IconRemove /> : ""}
          </div>
        </Col>
      </Row>
    )
  })

  return (
    <form onSubmit={goToNextPage}>
      <Row>
        <Col>
          <p>
            What does the Partner want in return for letting the Fan watch
            football? Don’t be afraid to get creative.
          </p>
        </Col>
      </Row>
      <br />
      {tradeOffComponents}
      <Row>
        <Col>
          <a onClick={addTradeOff} className="btn btn-next btn-blue-grey">
            + Add
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            type="submit"
            className="btn btn-next btn-blue-grey"
            data-nextbutton="next-step3"
          >
            Next
          </button>
        </Col>
      </Row>
    </form>
  )
}
const Step3 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3Component)

const Step4Component = props => {
  const {
    goToPage,
    goToNextPage,
    fanName,
    partnerName,
    favoriteTeamName,
    tradeOffs,
    gamePairs,
  } = props;

  const gamesList = gamePairs.map((gamePair, i) => {
    console.log(gamePair)

    if (gamePair != null) {
      if (
        gamePair[0] == "" ||
        gamePair[1] == "" ||
        gamePair[0] == undefined ||
        gamePair[1] == undefined
      ) {
        console.log("incomplete set")

        if (
          (gamePair[0] == "" && gamePair[1] == "") ||
          (gamePair[0] == undefined && gamePair[1] == undefined)
        ) {
          console.log("empty set")

          return (
            <div className="review-input" key={`gamePair-${i}`}>
              You have no game set.
            </div>
          )
        } else {
          return (
            <div className="review-input" key={`gamePair-${i}`}>
              You have an incomplete game set.
            </div>
          )
        }
      } else {
          return (
            <div className="review-input" key={`gamePair-${i}`}>
              {gamePair[0] + " vs. " + gamePair[1]}
            </div>
          )
      }
    } else {
      console.log("gamePair undefined");
    }
  })

  const tradeOffList = tradeOffs.map((tradeOff, i) => {
    // console.log(tradeOff)

    return (
      <div className="review-input" key={`tradeOff-${i}`}>
        {tradeOff}
      </div>
    )
  })

  return (
    <>
      <Row>
        <Col>
          <p>
            Double check the deets. Make sure everyone’s good with the game
            plan.
          </p>
          <p className="review-title">Contract Between</p>
          <div className="review-input">{fanName + " and " + partnerName}</div>
          <div className="review-edit" onClick={event => goToPage(0)}>
            Edit
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p className="review-title">Fan’s Favorite Team (Protected)</p>
          <div className="review-input">{favoriteTeamName}</div>
          <div className="review-edit" onClick={event => goToPage(1)}>
            Edit
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p className="review-title">Additional Games To Protect (Optional)</p>
          {gamesList}
          <div className="review-edit" onClick={event => goToPage(1)}>
            Edit
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p className="review-title">Partner's Trade-Offs</p>
          {tradeOffList}
          <div className="review-edit" onClick={event => goToPage(2)}>
            Edit
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div
            onClick={goToNextPage}
            className="btn btn-blue-grey"
            data-nextbutton="next-step4"
          >
            Complete The Prenup
          </div>
        </Col>
      </Row>
    </>
  )
}
const Step4 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step4Component)

export default MasterForm
