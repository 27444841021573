/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import config from "../utils/siteConfig"

class SEO extends React.Component {
  render() {
    let title;
    let description;
    let shareTitle;
    let shareFacebook;
    let shareTwitter;
    let shareImage;
    let imgWidth;
    let imgHeight;
    let pageUrl;


    // Set Default OpenGraph Parameters for Fallback
    title = config.siteTitle;
    description = config.siteDescription;
    shareTitle = config.shareTitle;
    shareFacebook = config.shareDescription;
    shareTwitter = config.shareTwitterDescription;
    shareImage = config.siteUrl + config.shareImage;
    imgWidth = config.shareImageWidth;
    imgHeight = config.shareImageHeight;
    pageUrl = config.siteUrl;

    // Default Website Schema
    const schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        'url': pageUrl,
        'name': title,
        'description': description,
        'alternateName': config.siteTitleAlt ? config.siteTitleAlt : '',
      },
    ]

    return (
      <Helmet>
        {/* General tags */}
        <meta name="image" content={shareImage} />
        {/* <meta name="description" content={description} /> */}

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:title" content={shareTitle} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={shareImage} />
        <meta property="og:image:width" content={imgWidth} />
        <meta property="og:image:height" content={imgHeight} />
        <meta property="og:description" content={shareFacebook} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={config.userTwitter ? config.userTwitter : ""}
        />
        <meta name="twitter:title" content={shareTitle} />
        <meta name="twitter:image" content={shareImage} />
        <meta name="twitter:description" content={shareTwitter} />
      </Helmet>
    )
  }
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
